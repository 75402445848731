import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FetchProvider } from './src/context/fetch';
import { MetadataProvider } from './src/context/metadata';
import './src/layout/index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 100,
    },
    mutations: {},
  },
});

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <FetchProvider>
      <QueryClientProvider client={queryClient}>
        <MetadataProvider>{element}</MetadataProvider>
      </QueryClientProvider>
    </FetchProvider>
  );
};
