import fetch from 'isomorphic-fetch';

export type Appfetch = <T>(url: string, options?: any) => Promise<T>;

class ValidationError extends Error {
  data: object;

  constructor(message: string, data: object) {
    super(message);
    this.name = 'ValidationError';
    this.data = data;
  }
}

export const AUTH_HEADER_NAME = 'Authorization';

export const json = (body: any) => {
  return new Blob([JSON.stringify(body)], { type: 'application/json' });
};

export const appfetch = <T>(url: string, options: RequestInit = {}): Promise<T> => {
  // Cancel logic
  const controller = new AbortController();

  // do fetch
  const responsePromise: Promise<Response> = fetch(url, {
    method: 'GET',
    ...options,
    headers: new Headers(options.headers || {}),
    signal: controller.signal
  });

  // handle result
  const returnPromise = responsePromise.then(async (response: Response) => {
    if (response.ok) {
      return (response.status === 200 ? await response.json() : undefined) as unknown as T;
    } else {
      switch (response.status) {
        case 400:
          const data = await response.json();
          throw new ValidationError(response.statusText, data);
        default:
          throw new Error(response.statusText);
      }
    }
  });

  (returnPromise as any).cancel = controller.abort;
  return returnPromise;
};

export const buildBearerContent = (idToken: string) => `Bearer ${idToken}`;
