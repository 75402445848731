import { graphql, useStaticQuery } from 'gatsby';
import React, { PropsWithChildren, useContext, useMemo } from 'react';

type ContextValue = {
  phone: string;
  email: string;
  siteUrl: string;
  siteTitle: string;
  storageUrl: string;
  fax: string;
  billing: {
    vat: string;
    iban: string;
    swift: string;
  };
  timezoneOffset: string;
  workingSchedule: { day: string; time: string }[];
  address: {
    street: string;
    city: string;
    country: string;
  };
};

const MetadataContext = React.createContext<ContextValue | undefined>(undefined);

const MetadataProvider = (props: PropsWithChildren<any>) => {
  const {
    site: {
      siteMetadata: {
        email,
        phone,
        siteUrl,
        storageUrl,
        title,
        address,
        fax,
        timezoneOffset,
        billing,
        workingSchedule,
      },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          email
          phone
          title
          siteUrl
          timezoneOffset
          storageUrl
          fax
          billing {
            vat
            iban
            swift
          }
          workingSchedule {
            day
            time
          }
          address {
            city
            street
            country
          }
        }
      }
    }
  `);

  const value = useMemo(
    () => ({
      email,
      phone,
      siteTitle: title,
      siteUrl,
      storageUrl,
      address,
      fax,
      timezoneOffset,
      billing,
      workingSchedule,
    }),
    [
      email,
      phone,
      siteUrl,
      storageUrl,
      title,
      address,
      fax,
      billing,
      workingSchedule,
      timezoneOffset,
    ]
  );
  return <MetadataContext.Provider value={value} {...props} />;
};

const useMetadata = (): ContextValue => {
  const context = useContext(MetadataContext);
  if (context === undefined) {
    throw new Error('useMetadata must be used within an MetadataProvider');
  }
  return context;
};

export { useMetadata, MetadataProvider };
