import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { appfetch } from '../../utils/httpClient';

export type AuthFetch = <T>(url: string, options?: RequestInit) => Promise<T>;

type ContextValue = {
  authFetch: AuthFetch;
};

const FetchContext = React.createContext<ContextValue | undefined>(undefined);

const FetchProvider = (props: PropsWithChildren<any>) => {
  const authFetch = useCallback(async (url: string, options: RequestInit = {}) => {
    const newOptions = {
      ...options,
      headers: {
        ...options.headers,
      },
    };

    return appfetch(url, newOptions);
  }, []);

  const value = useMemo(
    () => ({
      authFetch,
    }),
    [authFetch]
  );

  return <FetchContext.Provider value={value} {...props} />;
};

const useFetch = (): ContextValue => {
  const context = useContext(FetchContext);
  if (context === undefined) {
    throw new Error('useFetch must be used within an FetchProvider');
  }
  return context;
};

export { useFetch, FetchProvider };
